import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/services/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { nameKey: 'BULKLOAD' },
    children:[
      {
        path:'',
        pathMatch:'full',
        redirectTo: 'bulk-load'
      },
      {
        path: 'bulk-load',
        loadChildren: () => import('./pages/bulk-load-employees/bulk-load-employees.module').then((m) => m.BulkLoadEmployeesModule),
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'bulk-load'
  }
 
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports:[
    RouterModule
  ]
})
export class AppRoutingModule { }
