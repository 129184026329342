import { Component } from '@angular/core';
import { Router } from '@angular/router';

interface Routes{
  url:string;
  title:string;
  text?:string
  subtext?:string
  link:string
}

@Component({
  selector: 'app-bread-crum',
  templateUrl: './bread-crum.component.html',
  styles: [
  ]
})
export class BreadCrumComponent  {

  routes: Routes[] = [
    {
      url: '',
      title: '',
      link: '/interview-list',
      text: 'Carga Masiva'
    },
  ]
  constructor( public router:Router) { }

 get breadcrumb(){
  return this.routes.find((route) => this.router.url.includes(route.url));
 }

}
