<div id="kt_quick_user" class="offcanvas offcanvas-right p-3" [ngClass]="{'offcanvas-on': opened}">
    <button (click)="close.emit(false)" class="btn btn-xs btn-icon btn-light btn-hover-danger "
        id="kt_quick_user_close">
        <i class="ki ki-close icon-xs text-muted"></i>
    </button>
    <h3 class="font-weight-bold m-0 text-center my-5 font-size-h1 text-white">
        Perfil de Usuario
    </h3>
    <div class="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y text-center" style=" overflow: hidden">
        <div class="my-5">
            <div class="symbol symbol-130">
                <div class="symbol-label rounded-circle img-fluid shadow" [style.backgroundImage]="'url('+image+')'">
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center mt-3">
                <span class=" font-weight-bold font-size-h6 text-dark-75 mb-1">
                    {{account!.firstName}} {{account!.lastName}}
                </span>
                <div class="navi mb-1">

                    <span class="navi-text text-muted font-size-h7">
                        {{ account!.uEmail }}
                    </span>

                </div>
                <span class="font-size-h7 text-dark-75">
                    {{account!.companyName}}
                </span>
                <div class="navi mb-4">
                    <a class="btn btn-sm btn-danger font-weight-bolder py-2 px-5 mt-5 " (click)="logout()">
                        <i class="flaticon-salida icon-1x" data-toggle="tooltip"></i>
                        Salir</a>
                </div>
                <div class="item-pu mt-4 mb-5"></div>
            </div>
        </div>
    </div>
   

</div>
<div (click)="close.emit(false)" id="divOverlay" [ngClass]="{'offcanvas-overlay': opened}"></div>