import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class docService {
    private readonly url_api_doc = environment.api_doc
    constructor(private _http: HttpClient) { }

    getDocument(guid: string): Observable<any> {
        return this._http.get<any>(`${this.url_api_doc}/Document?guid=${guid}`);
    }

}
