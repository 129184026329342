import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { LayaoutComponent } from './layaout/layaout.component';
import { RouterModule } from '@angular/router';
import { BreadCrumComponent } from './bread-crum/bread-crum.component';
import { CardUserComponent } from './card-user/card-user.component';
import { LoadingComponent } from './loading/loading.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderContentComponent } from './header/header-content/header-content.component';
import { NotificationComponent } from './header/notification/notification.component';
import { NotificationContentComponent } from './header/notification-content/notification-content.component';



@NgModule({
  declarations: [
    HeaderComponent,
    SideBarComponent,
    MobileHeaderComponent,
    LayaoutComponent,
    BreadCrumComponent,
    CardUserComponent,
    LoadingComponent,
    FooterComponent,
    HeaderContentComponent,
    NotificationComponent,
    NotificationContentComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    LayaoutComponent,
    LoadingComponent
  ]
})
export class SharedModule { }
