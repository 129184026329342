import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { Account } from '../models/account.model';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit {

  opened = false

  account: Account = this.accountService.accountValue;

  constructor(private accountService: AccountService, private dataService: DataService) { }

  ngAfterViewInit(): void {
    
  }

  ngOnInit(): void {
    this.dataService.account$.subscribe(user => {
      this.account = user;
    })
  }

}
