import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Account, IRefreshTokenModel } from '../models/account.model';
import { ResponseModel } from '../models/strongly-typed-response.model';


@Injectable({ providedIn: 'root' })
export class AccountService {
  private accountSubject!: BehaviorSubject<Account>;
  public account!: Observable<Account>;
  public env = environment.lobbyUrl;
  public baseUrl = `${environment.api_lobby}/DecodeToken`;
  public apiLobby = environment.api_lobby;

  public refreshTokenUrl: string = "user/refreshToken";

  constructor(
    private router: Router,
    private http: HttpClient,
    private dataService: DataService,
    private toast: ToastrService) {

      const acc: Account = {
        userId: '',
        uEmail: '',
        companyId: 0,
        companyName: '',
        companyEmail: '',
        name: '',
        jwtToken: '',
        employeeNumber: '',
        employeeId: '',
        UserImage: '',
        userName: '',
        status: false,
        firstName: '',
        lastName: '',
        useCollectiveVacations: false,
        userTypeId: 0,
        identification: ''
      }

      this.accountSubject = new BehaviorSubject<Account>(acc);
      this.account = this.accountSubject.asObservable();
  }

  public get accountValue(): Account {
    const Data = this.dataService.GetUserData();

    if (Data) {
      this.accountSubject = new BehaviorSubject<Account>(Data);
    } else {
      //this.accountSubject = new BehaviorSubject<Account>(new Account());
    }

    return this.accountSubject.value;
  }

  getAccountSubject(){
      return this.accountSubject.asObservable();
  }

  setAccountSubject(account: Account){
    this.accountSubject.next(account);
  }

  logout() {
    this.dataService.IsLoading$.emit(false);
    localStorage.removeItem('Usr-Account');
    localStorage.removeItem('token');
    localStorage.removeItem('Usr-Modules');
    localStorage.removeItem('Usr-Privileges');
    //let url = window.location.href

    // if (url.indexOf('localhost') >= 0) {
    //   window.location.replace('http://localhost:4200/')
    // } else {
    //   window.location.replace(this.env);
    // }
    window.location.replace(`${this.env}/account/log-out`);
  }

  logoutWithAuth(){
    this.dataService.IsLoading$.emit(false);
    localStorage.removeItem('Usr-Account');
    localStorage.removeItem('token');
    localStorage.removeItem('Usr-Modules');
    localStorage.removeItem('Usr-Privileges');
    //let url = window.location.href

    // if (url.indexOf('localhost') >= 0) {
    //   window.location.replace('http://localhost:4200/')
    // } else {
    //   window.location.replace(this.env);
    // }
    window.location.replace(`${this.env}`);
  }

  validateToken(token: string) {
    let model = {
      userToken: token,
      nameKey: "HRM"
    }
    return this.http.post<ResponseModel<any>>(this.baseUrl ,model);
  }

  getUserInfo(userId:number){
    return this.http.get(`${environment.api_lobby}/user/detail?UserId=${userId}`)
  }

  sendValidationCode(body: { companyId: number, userId: number, email: string }) {
    return this.http.post(`${this.apiLobby}/User/PasswordVerficationCode`, body)
  }

  updatePassword(passwordData:any) {
    return this.http.post(`${this.apiLobby}/User/ChangePassword`, passwordData)
  }
  refreshToken(model: IRefreshTokenModel){
    return this.http.post<ResponseModel<IRefreshTokenModel>>(`${environment.api_lobby}/${this.refreshTokenUrl}`,model);
  }


}
