import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  loadingMap: Map<HttpRequest<any>, boolean> = new Map<HttpRequest<any>, boolean>();

  constructor() {

  }

  setLoading(loading: boolean, request: HttpRequest<any>): void {
      if(!request) {
          throw new Error('The request must be provided to the LoadingService.setLoading function');
      }
      if (loading === true) {
          this.loadingMap.set(request, loading);
          this.isLoading.next(true);
      } else if (loading === false && this.loadingMap.has(request)) {
          this.loadingMap.delete(request);
      }

      if (this.loadingMap.size === 0) {
          this.isLoading.next(false);
      }
  }
}
