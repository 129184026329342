import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ThirdPartyModule } from '../third-party/third-party.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    SearchBarComponent,
    PaginatorComponent
  ],
  imports: [
    CommonModule,
    ThirdPartyModule,
    FormsModule
  ],
  exports:[
    SearchBarComponent,
    PaginatorComponent
  ]
})
export class ComponentsModule { }
