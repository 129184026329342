
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { DataService } from '../../services/data.service';
import { AuthInfoService } from '../../services/auth-info.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../services/account.service';
import { docService } from '../../services/doc.service';
import { Account } from '../../models/account.model';


@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.css'],
})
export class HeaderContentComponent implements OnInit, AfterViewInit {
  @Input() opened = false
  @Output() close = new EventEmitter<boolean>()

  userDeparment: any;
  hasAPosition: boolean = false;

  account:Account = this.accountService.accountValue;

  image: string = 'assets/images/no-user.png';

  constructor(private ds: DataService,
    private authInfoService: AuthInfoService,
    private _docService: docService,
    private toast: ToastrService,
    private dialog: MatDialog,
    private accountService: AccountService) {

  }

  ngAfterViewInit(): void {
    if (this.authInfoService.getUser()?.UserImage) {
      this.getUserImage(this.authInfoService.getUser()?.UserImage);
    }

    this.account = this.accountService.accountValue;
  }

  ngOnInit(): void {
    this.SubscribeEvents();
  }

  SubscribeEvents(): void {
    this.ds.account$.subscribe(user => {
      this.account = user;
      this.getUserImage(user?.UserImage);
    })
  }

  logout(): void {
    this.accountService.logout();
  }

  closeSidebar() {
    this.close.emit(false)
    let classesOverly: any = document.getElementsByClassName("offcanvas-overlay");

    classesOverly.forEach((element:any) => {
      element.classList.remove('offcanvas-overlay');
    });
  }

  getUserImage(guid:any) {
    this._docService.getDocument(guid).subscribe((res: any) => {
      this.image = res.data ? res.data : 'assets/images/no-user.png';
    }, error => {
      this.image = 'assets/images/no-user.png';
    });
  }

}
