<div class="footer py-4 d-flex flex-lg-column" >
    <div class="container-fluid  align-items-center justify-content-between">
        <div class="row ">
            <div class="col text-center">

                <h5>{{text}}</h5>

            </div>
        </div>
    </div>
</div>