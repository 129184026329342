import { NgModule } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [
    
  ],
  imports:[
    NgMultiSelectDropDownModule.forRoot(),
    SelectDropDownModule,
    MatDialogModule
  ],
  exports:[
    NgMultiSelectDropDownModule,
    SelectDropDownModule,
    MatDialogModule
  ]
})
export class ThirdPartyModule { }
