import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-user',
  templateUrl: './card-user.component.html',
  styles: [
  ]
})
export class CardUserComponent implements OnInit {
  @Input() opened = false
  @Output() close = new EventEmitter<boolean>()
  constructor() { }
  image: string = 'assets/images/no-user.png';
  ngOnInit(): void {
  }








  
}
