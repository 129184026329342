<app-mobile-header></app-mobile-header>

<div class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <app-side-bar></app-side-bar>
        <div class="d-flex flex-column flex-row-fluid wrapper" style="min-height: 100vh !important;" id="kt_wrapper">
            <app-header></app-header>
            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid"  id="kt_content">
                <app-bread-crum></app-bread-crum>

                <div class="d-flex flex-column-fluid">
                    <div class="container-fluid">
                        <div class="row gutter-b">
                            <div class="col-lg-12">
                                <ng-content></ng-content>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-footer></app-footer>
        </div>

    </div>

</div>
<div id="kt_scrolltop" class="scrolltop forward-btn">
    <i class="flaticon-up-arrow-1"></i>
</div>