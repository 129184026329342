<div class="aside aside-left d-flex aside-fixed bg-aside" id="kt_aside">
    <div class="aside-primary d-flex flex-column align-items-center flex-row-auto">
      <div class="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12">
        <div data-toggle="tooltip" data-original-title="Volver" data-placement="right" id="logo">
          <a routerLink="/">
            <img alt="Logo" src="/assets/logos/logo-sigei-100-02.png" class="max-h-70px logo-font" />
          </a>
          <h1 class="text-white ms-1">SIGEI</h1>
        </div>
      </div>
      <div class="aside-nav d-flex flex-column align-items-center flex-column-fluid text-center text-white py-5 side">
        <ul class="nav flex-column" role="tablist">
          
          <li *ngFor="let route of routes"
            class="nav-item mb-5"
            id="reclutamiento_seleccion_toggle" 
            data-toggle="tooltip" 
            data-placement="right"
            data-container="body" 
            data-boundary="window"
            title="{{route.name}}" 
            [attr.data-original-title]="route.name"
            >
            <a 
              href="#" 
              class="nav-link btn btn-icon btn-clean btn-lg"
              [routerLink]="[route.route]"
              routerLinkActive="active"
              data-toggle="tab"
              data-target="#kt_aside_tab_1" 
              role="tab" 
              aria-selected="true">
              <i [class]="route.icon"></i>
            </a>
            <span class="d-des-none">{{route.name}}</span>
          </li>

        </ul>
        <!--end::Nav-->
        <div class="ps__rail-x">
          <div class="ps__thumb-x"></div>
        </div>
        <div class="ps__rail-y">
          <div class="ps__thumb-y"></div>
        </div>
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
        <img alt="Logo" src="/assets/logos/logo-sigei-100-02.png" class="max-h-30px opacity-42" />
        <span class="font-size-h3 font-weight-bold opacity-42 text-white">SIGEI</span>

      </div>
      <!--end::Footer-->
    </div>
  </div>
  <div class="odffcanvas-overlayy">
  
  </div>
 <!--  <app-side-bar-content class="sidebar_content"></app-side-bar-content> -->
  
  