<div id="kt_header" class="header header-fixed">
    <!--begin::Container-->
    <div class="container-fluid d-flex align-items-stretch justify-content-between">
        <!--begin::Page Title-->
        <h1 class="d-flex align-items-center text-primary font-weight-bold my-1 mr-3">
          <i class="flaticon-recursos-humanos-6 icon-3x mr-4 text-primary"></i><span style="font-size: 1.3vw;">Carga Masiva -</span><span class="text-danger ms-2" style="font-size: 1.2vw;">{{account!.companyName}}</span>
        </h1>
        <!--end::Page Title-->
        <!--begin::Topbar-->
        <div class="topbar">
            <!--begin::Notifications-->
            <div class="dropdown">
                <!--begin::Toggle-->
                <!-- <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" aria-expanded="false">
          <div class=" btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-danger">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z"
                    fill="#000000"/>
                  <rect fill="#000000" opacity="0.3" x="10" y="16" width="4" height="4" rx="2" />
                </g>
              </svg>
            </span>
            <span
              class="
                label label-sm label-light-danger label-rounded
                font-weight-bolder
                position-absolute
                top-0
                right-0
                mt-1
                mr-1
              "
              >3</span
            >
            <span class="pulse-ring"></span>
          </div>
        </div> -->
                <!--end::Toggle-->
                <!--begin::Dropdown-->
                <app-notification-content></app-notification-content>
                <!--end::Dropdown-->
            </div>

            <!--end::Notifications-->

            <!--begin::User-->
            <div class="topbar-item" *ngIf="account">
                <div class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" (click)="opened=true" id="kt_quick_user_toggle">
                    <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hola,</span>
                    <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ account!.firstName }} {{account!.lastName}}</span>
                    <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
            <span class="symbol-label font-size-h5 font-weight-bold rounded-circle">{{account!.firstName!.charAt(0).toUpperCase() || ''}}</span>
                    </span>
                </div>
            </div>
            <!--end::User-->
        </div>
        <!--end::Topbar-->
    </div>
    <!--end::Container-->
</div>

<app-header-content [opened]="opened" (close)="opened=$event"></app-header-content>