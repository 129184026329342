import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styles: [
  ]
})
export class SearchBarComponent implements OnInit {
  @Input() records: any[]=[];
  @Input() itemForSearching!:{propkey:string, displayName:string}[];
  @Output() onChange = new EventEmitter<{records:any[], enable:boolean, returnValues?: boolean, values?: any}>();
  @Output() onKeyChange = new EventEmitter<string>();
  propList:{propKey:string, displayName:string}[]= new Array();
  @Input() keyValue!:string;

  @Input() returnValues: boolean = false;

  searchParameterValue!:{propKey:string, displayName:string};

  parameterValue!: {propKey:string, displayName:string};

  config = {
    displayKey: 'displayName',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder:'Buscar',
    searchOnKey:'displayName'
  };

  constructor(
    private _changeDet:ChangeDetectorRef,
    private _toastService: ToastrService
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.keyValue){
      this.filter(this.keyValue);
    }
  }

  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void {
        this.getProps(this.records);
        this._changeDet.detectChanges();
  }
  
  emitText(){
    this.onKeyChange.emit(this.keyValue)
  }

  getProps(model:any){
    for(let i=0; i<this.itemForSearching.length;i++){
      this.propList.push({propKey:this.itemForSearching[i].propkey, displayName: this.itemForSearching[i].displayName })
    }
  }

  filter(key:any){
    if(this.returnValues == true){
      const values = {
        text: this.keyValue,
        propKey: this.parameterValue
      }
      this.onChange.emit({enable: false, records: [], returnValues: this.returnValues, values:values });
      return
    }
    this.searchParameterValue = this.parameterValue;
    key = key?.trimEnd()?.normalize('NFD')?.replace(/[\u0300-\u036f]/g,"")?.toLocaleLowerCase();
    let filteredRecords;
    if(!key || key.length === 0){
      let filteredRecords = {records:[...this.records], enable:false}
      this.onChange.emit(filteredRecords);
      return
    }

    else { 
      if(this.searchParameterValue.propKey == 'personalIdentification'){
        key = key.replaceAll('-', '')
      }
      let arr = []
      key = key.trim()
      let words: any
      if(key.includes(' ')){
        words = key.split(' ')
      }else{
        words = [key]
      }
      arr = this.records.filter(x => {
        const propKey = (x[this.searchParameterValue.propKey] ? x[this.searchParameterValue.propKey].toString() : '' as string).normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLocaleLowerCase()
        const keyParsed: any = (words[0] as string ).toLocaleLowerCase()
        return propKey.indexOf(keyParsed) != -1
      })
      for (let i = 1; i < words.length; i++) {
        arr = arr.filter(x => {
          const propKey = (x[this.searchParameterValue.propKey] ? x[this.searchParameterValue.propKey].toString() : '' as string).normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLocaleLowerCase()
          const keyParsed: any = (words[i] as string).toLocaleLowerCase()
          return propKey.indexOf(keyParsed) != -1
        })
      }
      filteredRecords = {
        records: arr,
        enable:true
      };
    }
    if(filteredRecords?.records?.length === 0){
      this._toastService.warning('No se encontraron registros con los criterios de búsqueda especificados');
    }
    this.onChange.emit(filteredRecords)
  }
  
  public setParameterSearch(key:any){
    this.onChangeParameterSearch(null);
  }

  onChangeParameterSearch(event:any){
    this.keyValue='';
    this.searchParameterValue=event;
    let filteredRecords = {records:this.records, enable:false}
    this.onChange.emit(filteredRecords);
  }
  
  isNumeric(num:any){
    return !isNaN(num)
  }
}
