import { Component } from '@angular/core';

@Component({
  selector: 'app-notification-content',
  templateUrl: './notification-content.component.html',
  styleUrls: ['./notification-content.component.css']
})
export class NotificationContentComponent {

}
