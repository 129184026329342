import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from './account.service';
import { DataService } from './data.service';
import { AccountPrivilege, ModuleList } from '../models/account.model';
import { ResponseModel } from '../models/strongly-typed-response.model';
@Injectable({
  providedIn: 'root'
})
export class AuthInfoService {

  public redirectUrl!: string ;

  constructor(private jwtHelper: JwtHelperService, private route: Router, private dataService: DataService, private accountService: AccountService,
    private toastService: ToastrService) { }


  getCompanyId():number{
    let account = this.dataService.GetUserData();
    return +account?.companyId;
 
  }

  getUserFullName():string{
    let account = this.dataService.GetUserData();
    return `${account?.firstName} ${account?.lastName}` ;
  }

  getUserId():number{
    let account = this.dataService.GetUserData();
    return +account?.userId;
  }

  getEmployeeId(){
    let account = this.dataService.GetUserData();
    return +account?.employeeId;
  }

  getEmployeeIdFromSession():number{
    let account = this.dataService.GetUserData();
    return +account?.employeeId;
  }

  getToken(){
    let token = localStorage.getItem('token');
    return token ? token : null;
  }

  getRefreshToken(){
    let refreshToken = localStorage.getItem('refreshToken');
    return refreshToken ? refreshToken : null;
  }

  getUser(){
    let account = this.dataService.GetUserData();
    return account;
  }

  async getUserModules(): Promise<ModuleList[]>{

    let response = await this.setUserOptions();
    

    if(response == false){
      return [];
    }

    const Data = localStorage.getItem('Usr-Modules');
    if (Data) {
      let UsrModules: ModuleList[];
      UsrModules= JSON.parse(Data);

      if (UsrModules) {
        return UsrModules;
      }else{
        return []
      }

    } else {
        return []
    }
  
  }

  async canUseModule(globalModule: string): Promise<boolean>{
    let modules = await this.getUserModules();
    if(modules.length > 0){
      let module = modules.find(x=>x.nameKey == globalModule);
      
      return module ? true:false;  
    }else{
      return false;
    }
  
  }

  getUserPriviliges(): AccountPrivilege[] {

    const Data = localStorage.getItem('Usr-Privileges');
    if (Data) {
      let UsrPriviliges: AccountPrivilege[];
      UsrPriviliges = JSON.parse(Data);
      if (UsrPriviliges) {
        return UsrPriviliges;
      }else{
        return []
      }
    } else {
        return []
    }
  }

  async setUserOptions(){
    let id = this.getUserId();

    if(!id){
      return;
    }

    let isValid: boolean = false;

    await this.accountService.getUserInfo(id).toPromise().then((res:any)=>{

      if (!res.succeded) {
        this.toastService.error(res.errors[0]);
        this.accountService.logout();
        isValid = false;
        return;
      }

      localStorage.setItem('Usr-Modules', JSON.stringify(res.singleResultData.modules));
      localStorage.setItem('Usr-Privileges', JSON.stringify(res.singleResultData.privileges));
      localStorage.setItem('refreshToken', res.singleResultData?.account?.refreshToken);

      let user = this.getUser();
      user.userTypeId = res.singleResultData?.account?.userTypeId;

      localStorage.setItem('Usr-Account', JSON.stringify(user));

      isValid = true;
      
      this.accountService.setAccountSubject(res.singleResultData.account);

    }).catch(err=>{
      isValid = false;
     this.toastService.error('No se ha podido obtener los privilegios del usuario');
    });

    return isValid;
  }

  async canUseOption(moduleKey:string, optionKey:string): Promise<boolean>{

    let privileges = this.getUserPriviliges();
    if(privileges.length > 0){
      let privilege = privileges.find(x=>x.nameKeyOption == optionKey && x.status == true && x.nameKeyModule == moduleKey);
    
      if(!privilege){
        this.toastService.warning('Usted no tiene acceso a esta pantalla, favor de comunicarse con Help Desk.')
      }


      return privilege ? true : false;  
    }else{
      return false;
    }
  }

  setPrivileges(privileges:any){
    const keys = Object.keys(privileges);
    const excludedKeys = ['nameKeyModule', 'nameKeyOption'];
    for (const privilegeKey of keys) {
      if (!excludedKeys.includes(privilegeKey)) {
        privileges[privilegeKey].value = this.canDoAction(privileges.nameKeyOption, privileges[privilegeKey].key);
      }
    }
    return privileges;
  }

  

  canDoAction(optionKey:string, actionKey:string): boolean{
    let privileges =  this.getUserPriviliges();
    if(privileges.length > 0){
      let privilege = privileges.find(x=>x.nameKeyOption == optionKey && x.status == true);

      if(!privilege){
        return false;
      }

      let action = privilege.allowActions.find(x=>x.nameKey == actionKey && x.status == true);
      
      return action ? true : false;  
    }else{
      return false;
    }
  }

  isAuthenticated() : boolean{
    const token = this.getToken();

    if(token == null){
      return false;
    }

    // true or false
    return true
  }

  doLogOut(){
    this.accountService.logout();
  }
}
