import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import * as signalR from "@microsoft/signalr"
import { IUserSession } from '../models/account.model';


@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  public data!: IUserSession;
  public listenedData!: IUserSession;

  private hubConnection!: signalR.HubConnection;
  private baseUrl = environment.api_lobby;

  constructor(private authInfo: AuthInfoService) {

  }

  public startConnection = () => {
    const url = this.baseUrl.replace('/api','');

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${url}/hubs/UserSession`)
      .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch((err) => console.log('Error while starting connection: ' + err))
  }

  public addSessionLogOutDataListener = () => {
    this.hubConnection.on('UserDesconnected', (data) => {
      this.listenedData = data;

      if(this.authInfo?.getUserId() == this.listenedData.userId){
        this.authInfo.doLogOut();
      }

    })
  }
}
