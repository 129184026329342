<ng-container *ngIf="loaderService.isLoading | async">
    <div class="loading-layer">
        <div class="loading-box">
            <div class="loading">
                <div class="loading-container">
                    <div class="loading-circle"></div>
                    <img id="loading-text" src="../../../assets/logos/logo-sigei-100-02.png" alt="">
                </div>
                <span class="text">CARGANDO...</span>
            </div>
        </div>
    </div>
</ng-container>