import { Component, OnInit } from '@angular/core';
import { SignalrService } from './shared/services/signalr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  constructor(private signalr: SignalrService){

  }

  ngOnInit(): void {
    this.signalr.startConnection();
    this.signalr.addSessionLogOutDataListener();
  }
}
