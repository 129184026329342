import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CaptionOptions } from '../enums/caption-options';
import { CaptionEventModel } from '../models/caption-event-model';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styles: [
  ]
})
export class PaginatorComponent {
  @Input() registerQuantity: number | undefined;
  @Input() records: any[] | undefined;
  @Input() originalRecordsLength: number | undefined;
  @Input() listedRecordsLength: number | undefined;
  pageModel: {count:number, offset:number, prev?:boolean, last?:any} = { count: environment.register_per_page, offset:0 };
  registerPerPage: number = environment.register_per_page;
  selectedPage = 1;
  firstPage = 1;
  lastPage: number  = 0;
  @Output() onChage = new EventEmitter<CaptionEventModel>();
  pagesOptions: {page?:number, records?:any[]}[]= [];
  minPageQuantityWithoutSpread = 5;
  middlePageQuantity = 3;
  initialMiddlePages = [2, 3, 4];
  

  ngOnChanges(): void {
    this.pagesOptions=[];
    this.generatePageOptions();
  }

  generatePageOptions() {
    const pagesQuantity = Math.ceil(
      ((this.registerQuantity) ? this.registerQuantity as number: 1) / this.registerPerPage
    );

    if(this.registerQuantity){
      for (let page = 1; page <= pagesQuantity; page++) {
        this.pagesOptions.push({page:page, records:this.records?.slice((page-1)*this.registerPerPage, page * this.registerPerPage ) });
      }
    }
    

    this.lastPage = this.pagesOptions.length;
    this.emitChange(CaptionOptions.ChangeRegisterPerPage);
  }

  nextPageCaption() {
    this.selectedPage++;
    this.emitChange(CaptionOptions.NextPage);
  }

  previousPageCaption() {
    this.selectedPage--;
    this.emitChange(CaptionOptions.PreviousPage);
  }

  getMidlePages(): Array<number> {
    if (this.selectedPage === this.lastPage - 1) {
      return [this.selectedPage - 2, this.selectedPage - 1, this.selectedPage];
    }

    if (this.selectedPage === this.firstPage + 1) {
      return this.initialMiddlePages;
    }

    if (
      this.selectedPage > this.firstPage &&
      this.selectedPage < this.lastPage
    ) {
      return [this.selectedPage - 1, this.selectedPage, this.selectedPage + 1];
    }

    if (this.selectedPage === this.lastPage) {
      return [
        this.selectedPage - 3,
        this.selectedPage - 2,
        this.selectedPage - 1,
      ];
    }

    return this.initialMiddlePages;
  }

  emitChange(option: string) {
    const emitter: CaptionEventModel = {
      registersPerPage: +this.registerPerPage,
      option,
      formattedRecords: this.pagesOptions,
      selectedPage:this.selectedPage
    };
    this.onChage.emit(emitter);
  }

  chageRegisterPerPage(registerPerPage: any) {
    this.selectedPage = this.firstPage;
    this.registerPerPage = registerPerPage?.target?.value;
    this.pagesOptions=[];
    this.generatePageOptions();
    this.emitChange(CaptionOptions.ChangeRegisterPerPage);
  }

  nextPage(registerPerPage: number): void {
    this.pageModel = {
      prev: false,
      last: this.pagesOptions[this.pagesOptions.length - 1].toString(),
      count: registerPerPage,
      offset:this.pageModel.offset+registerPerPage
    };
  }

  previousPage(registerPerPage: number): void {
    const firstPosition = 0;
    this.pageModel = {
      prev: true,
      last: this.pagesOptions[firstPosition].toString(),
      count: registerPerPage,
      offset : this.pageModel.offset-registerPerPage
    };

  }

}
