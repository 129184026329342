import { Component, OnInit } from '@angular/core';

interface Routes{
  name:string;
  route:string;
  icon:string;
} 

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styles: [
    
  ]
})
export class SideBarComponent implements OnInit {

  routes: Routes[] = [
    {
      name: "",
      route:"/",
      icon:'flaticon-recursos-humanos-6 icon-3x'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}